/// <reference path="_variables.scss" />
/*#region share widget */

.share-widget {
    margin-bottom: 20px;
    text-align: $sharing-widget-align;
}

.share-widget-title {
    font-weight: $sharing-widget-title-font-weight;
    display: $sharing-widget-title-display;
    margin: $sharing-widget-title-margin;
    text-transform: $sharing-widget-title-text-transform;
}

.share-widget .fab {
    font-size: 16px;
}

.share-widget .share-link {
    display: inline-block;
    color: #fff;
    padding: 1px 5px;
    background-color: #ccc;
    border: 1px solid #ccc;
}

.share-widget.large {
    .fab, .far {
        font-size: 26px;
        padding: 6px;
    }
    .far{
        padding-left:4px;
    }
}

.share-widget.rounded .share-link {
    border-radius: 50px;
    padding: 5px 7px;
}

.share-widget .share-link.facebook {
    background-color: $sharing-widget-facebook-colour;
    border: 1px solid $sharing-widget-facebook-colour;
}

.share-widget a.share-link.facebook:hover {
    background-color: #fff;
    color: $sharing-widget-facebook-colour;
    border: 1px solid $sharing-widget-facebook-colour;
}

.share-widget .share-link.twitter {
    background-color: $sharing-widget-twitter-colour;
    border: 1px solid $sharing-widget-twitter-colour;
}

.share-widget a.share-link.twitter:hover {
    background-color: #fff;
    color: $sharing-widget-twitter-colour;
    border: 1px solid $sharing-widget-twitter-colour;
}

.share-widget .share-link.linkedin {
    background-color: $sharing-widget-linkedin-colour;
    border: 1px solid $sharing-widget-linkedin-colour;
}

.share-widget a.share-link.linkedin:hover {
    background-color: #fff;
    color: $sharing-widget-linkedin-colour;
    border: 1px solid $sharing-widget-linkedin-colour;
}

.share-widget .share-link.googleplus {
    background-color: $sharing-widget-googleplus-colour;
    border: 1px solid $sharing-widget-googleplus-colour;
}

.share-widget a.share-link.googleplus:hover {
    background-color: #fff;
    color: $sharing-widget-googleplus-colour;
    border: 1px solid $sharing-widget-googleplus-colour;
}

.share-widget .share-link.pinterest {
    background-color: $sharing-widget-pinterest-colour;
    border: 1px solid $sharing-widget-pinterest-colour;
}

.share-widget a.share-link.pinterest:hover {
    background-color: #fff;
    color: $sharing-widget-pinterest-colour;
    border: 1px solid $sharing-widget-pinterest-colour;
}

.share-widget .share-link.email {
    background-color: $sharing-widget-email-colour;
    border: 1px solid $sharing-widget-email-colour;
}

.share-widget a.share-link.email:hover {
    background-color: #fff;
    color: $sharing-widget-email-colour;
    border: 1px solid $sharing-widget-email-colour;
}

.share-widget.large.rounded .share-link.email .fa {
    padding-left: 4px;
}

/*#endregion */
