/// <reference path="_variables.scss" />

.card.card-with-overlay {
    height: 280px;
    background-color: $page-card-bg-color;
    transition: background-color 1s ease;
    border: none;
    border-radius: $page-card-border-radius;

    &:hover {
        background-color: $page-card-bg-color-hover;
    }

    .card-body {
        position: absolute;
        top: 35%;
        bottom: 50%;
        left: 0;
        right: 0;
        color: $page-card-font-color;
        text-align: $page-card-text-align;
        text-shadow: 2px 2px 2px #000;


        .h5 {
            color: $page-card-font-color;
            font-size: 2.5rem;
            font-weight: 300;
        }
    }

    &.card-with-overlay {
        &.card-with-image {

            img {
                filter: brightness(80%);
                transition: all 1s ease;
                height: 100%;
            }

            &:hover {
                img {
                    filter: brightness(100%);
                }
            }

            &:hover {
                .h5 {
                    color: $page-card-overlay-font-color;
                }
            }
        }
    }
}
