/// <reference path="_variables.scss" />
/*#region share widget */
.cookie-banner {
    background: $cookie-banner-bg-color;
    border: $cookie-banner-border;
    border-top: $cookie-banner-border-top;
    padding: 2rem;
    color: $cookie-banner-text-color;

    a {
        color: $cookie-banner-text-color;
    }
}
