/// <reference path="_variables.scss" />

@media only screen and (max-width: 1024px) {
    .btn-w-100-md {
        display: block;
        width: 100%;
    }
}
.btn-w-100-md {
    min-width:25%;
}

.field-validation-valid {
    display: none;
}
.form-label{
    font-weight:500;
}
.honey-pot,
.form-hp-area {
    input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    label {
        display: none;
    }
}
.form-gdpr-text-container {
    background: $form-gdpr-block-bg-color;
    border: $form-gdpr-block-border;
    padding: $form-gdpr-block-padding;
    margin: $form-gdpr-block-margin;

    h2, h3, h4 {
        font-size: $form-gdpr-block-header-size;
    }
}