@import url('https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500|Roboto:100,300,400,500,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css');
@import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css";
@import '_variables.scss';
@import '../../components/_tabs.scss';
@import '../../components/_carousel.scss';
@import '../../components/_cookies.scss';
@import '../../components/_forms.scss';
@import '../../components/_page-cards.scss';
@import '../../components/_sharing-widget.scss';

body {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #666;
    font-weight: 300;

    a {
        color: #337ab7;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    b, strong {
        font-weight: 600;
    }
}

header {
    background-color: $primary-color;
    color: #fff;
    padding: 1rem 0;

    .header-sub-title {
        color: #333;
        font-weight: 300;
        padding-left: 15px;
        margin-left: 5px;
        text-transform: uppercase;
        vertical-align: text-top;
        font-size: 18px;
    }

    img.site-logo {
        max-width: 100%;
    }
}

.navbar-container {
    background-color: $secondary-color;

    nav {
        @media only screen and (min-width: 1024px) {
            padding: 0;
        }

        .navbar-nav {


            .nav-link {
                transition: background-color 0.8s ease-in-out;
                padding: .75rem;

                &:hover {
                    background-color: $primary-color;
                    text-decoration: none;
                    color: #666 !important;
                }
            }
        }

        .navbar-nav .nav-link,
        .navbar-nav .nav-link.active,
        .navbar-nav .nav-link.show,
        .nav-link .dropdown-toggle {
            color: #fff;
            font-weight: 300;
            font-size: 18px;
            text-transform: uppercase;
        }

        @media only screen and (min-width: 768px) {
            .navbar-default .navbar-nav > li > a, .dropdown-menu > li > a {
                transition: all 0.4s ease-in-out;
            }
        }

        .navbar-toggler {
            background-color: $primary-color;
            border-radius: 0;
        }
    }
}

.subnav-container {
    /*display: none;*/
    background-color: #585857;
    padding: 10px 10px 30px;
    border-bottom: 1px solid #d0c651;
    font-weight: 100;
    text-transform: uppercase;
    --sub-nav-font-color: #fff;
    color: var(--sub-nav-font-color);

    a {
        color: var(--sub-nav-font-color);
    }

    .subnav {

        &--section-title {
            font-size: 180%;
            margin-bottom: 1rem;
            display: block;
            text-decoration: none;
            border-bottom: 1px dotted #585857;

            &:hover {
                border-bottom: 1px dotted #969151;
            }
        }

        &--menu {
            list-style: none;
            padding: 0;

            li {

                @media only screen and (min-width: 1024px) {
                    display: inline-block;
                    float: left;
                    width: 25%;
                }

                a {
                    display: block;
                    padding: .5rem 0;
                    border-bottom: 1px dotted #969151;
                    text-decoration: none;

                    @media only screen and (min-width: 1024px) {
                        border-bottom: 1px dotted #585857;
                    }

                    &:hover {
                        border-color: #969151;
                    }
                }
            }
        }
    }
}

.main-content {
    padding: $main-content-padding-top 0 2rem;

    .grid-area {
        .container, .container-lg, .container-fluid {
            padding-top: 2rem;
        }
    }
}

footer {
    color: #fff;
    font-weight: 100;


    a {
        color: #8f8f8f;
        transition: color 0.4s ease-in-out;

        &:hover {
            color: #d0c651;
        }
    }

    .footer-site-title {
        font-size: 24px;
    }

    .footer-site-title, .footer-site-sub-title {
        color: #fff;
        font-weight: 100;
    }

    .grid-component-social-share {
        .share-widget-title {
            display: inline-block;
        }
    }

    .grid-component-link-list {
        ul {
            padding-left: 0;
            list-style: none;

            li {
                float: left;
                width: 50%;

                @media only screen and (min-width: 1024px) {
                    width: 33%;
                }

                margin-bottom: 1rem;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .footer-share-block {
        padding: 15px 0;
        color: #959798;
        background-color: #444442;
    }

    .footer-logo-block {
        padding: 2rem 0;

        img {
            max-width: 320px;
        }

        @media only screen and (max-width: 1024px) {
            .row > .col-sm-4 {
                width: 33%;

                img {
                    max-width: 100%;
                }
            }
        }
    }

    .footer-main {
        color: #8f8f8f;
        background: #212121;
        padding-top: 20px;
        padding-bottom: 20px;

        .grid-component-social-channels ul {
            list-style: none;
            padding: 0;

            li {
                float: left;
                margin-right: 1rem;
            }
        }
    }
}

.pull-up {
    margin-top: -$main-content-padding-top;
}

.page-title, .simple-form-content h2 {
    font-family: 'Raleway', sans-serif;
    font-size: 36px;
    color: #444442;
    font-weight: 400;
}

.grid-component-carousel {
    img {
        background-size: cover;
        height: 400px;
        background-repeat: no-repeat;
        background-color: #f2ebeb;
    }

    .carousel-caption {
        background: none;

        h5 {
            text-shadow: 2px 1px 3px black;
            font-size: 48px;
            font-weight: 300;
        }

        p {
            font-weight: 300;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
            font-size: 22px;
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: #444442;
    border-color: #d0c651;
    border-radius: 0;
    min-width: 160px;

    &:hover,
    &:active {
        color: #444442;
        background-color: #bdb55a;
        border-color: #444442;
    }
}

.card {
    margin-bottom: 1rem;

    &.card-with-overlay {
        &:hover {
            .card-body .h5,
            .card-body .card-text {
                color: #fff;
            }
        }

        .card-body {
            top: 20%;

            @media only screen and (min-width: 1024px) {
                top: 28%;
            }
        }

        .card-body .h5,
        .card-body .card-text {
            font-weight: 100;
            color: #444442;
            text-shadow: none;
        }
    }
}

.callout-component {
    background-color: #d0c651;
    color: #444442;
    text-align: center;
    padding: 2rem 0;
    margin-bottom: 1rem;

    h2 {
        margin-top: 0;
        font-weight: 300;
        color: #444442;
    }

    .btn {
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 10px;
        color: #fff;
        background-color: #444442;
        border-color: #d0c651;
        display: block;
        border-radius: 0;

        &:hover {
            color: #444442;
            background-color: #bdb55a;
            border-color: #444442;
        }
    }
}
/*#region price list table */
.table.price-list {
    margin: 30px auto;
}

.table.price-list thead th {
    background-color: #d0c651;
    color: #333;
    font-weight: 500;
}

.table.price-list thead th:last-child {
    width: 100px;
    text-align: center;
}

.table.price-list tbody td {
    color: #333;
}

.table.price-list tbody td:last-child:not([colspan]) {
    text-align: center;
    font-weight: 500;
    vertical-align: middle;
}

.table.price-list td[colspan] {
    text-align: center;
}

.table.price-list tbody > tr:hover {
    background-color: #d4d4ab !important;
}

@media only screen and (min-width: 768px) {
    table.table-opening-times {
        width: 50%;
        margin: auto;
    }
}

/*#endregion */
.other-treatment-price-cards {

    .col-sm-4 > div {
        border-bottom: 2px dotted #000;
        padding: 10px;
        margin-bottom: 20px;
        background-color: #dcd9b1;

        h3 {
            font-weight: 300;
        }

        table {
            background-color: transparent;
        }
    }
}

.article-content {
    img {
        max-width: 100%;

        @media only screen and (max-width: 600px) {
            height: auto;
            margin-bottom: 1rem;
        }
    }

    .article-category-link {
        background-color: #F6F6F6;
        border: 1px solid #dedede;
        padding: .5rem;
        color: #000;
        margin-right: .5rem;

        &:hover {
            text-decoration: none;
            background-color: #dedede;
        }
    }
}
