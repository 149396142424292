/// <reference path="_variables.scss" />

.grid-component-carousel {
    .carousel-caption {
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(0 0 0 / 50%);
        color: #fff;

        h5 {
            color: #fff;
        }
    }

    .carousel .carousel-item img {
        object-fit: cover;
        height: $carousel-image-height;
        object-position: center;
        padding: 0;
    }

    @media only screen and (max-width: 1024px) {
        .carousel .carousel-item img {
            min-height: 400px;
            max-height: 400px;
        }
    }
}
