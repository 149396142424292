/// <reference path="_variables.scss" />

.grid-component {
    &.grid-component-tabbed-content {
        .tab-content {
            padding: 2rem 1rem;
            border: 1px solid #dee2e6;
        }

        .nav-item {
            .nav-link {
                color: $tab-button-text-color-inactive;
                background-color: $tab-button-color-inactive;
                border: $tab-button-border-inactive;

                &:hover {
                    background-color: #dee2e6;
                }

                &.active {
                    background-color: $tab-button-color;
                    color: $tab-button-text-color;
                }
            }
        }

        &.horizontal-tabs {
            .nav-item {
                .nav-link {
                    border-radius: $tab-button-border-radius;
                    margin-right: 1px;

                    &.active {
                        background-color: $tab-button-color;
                        border-bottom: none;
                    }
                }
            }

            .tab-content {
                border-top: none;
            }
        }
        /* disable stacked tabs when on small screen */
        @media only screen and (max-width: 576px) {

            &.vertical-tabs {
                .nav-tabs .nav-link {
                    text-align: left;
                    border: 1px solid #dee2e6;
                    border-right: none;
                    padding: 1rem;
                }

                .nav-tabs .nav-link.active {
                    border-bottom: none;
                    border-left: 4px solid;
                }

                .nav-justified > .nav-link, .nav-justified .nav-item {
                    flex-basis: initial;
                    flex-grow: initial;
                    width: 100%;
                    display: block;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            &.vertical-tabs {
                .nav-tabs {
                    float: left;
                    width: 25%;
                    display: inline-block;

                    .nav-link {
                        text-align: left;
                        border: 1px solid $tab-button-border-inactive;
                        border-right: none;
                        padding: 1rem;

                        &.active {
                            border-bottom: none;
                            border-left: 4px solid $tab-button-vertical-border-color;
                        }
                    }
                }

                .tab-content {
                    float: left;
                    width: 75%;
                    min-height: 300px;
                }
            }
        }
    }
}
